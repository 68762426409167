*,
*:before,
*:after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html {
    overflow-y: scroll;

    --header-height: 4rem;

    --color-green: #006600;
    --color-red: #C32222;
    --color-gray-700: #bfbfbf;
    --color-correct-letter: #008000;
    --color-incorrect-letter: #404040;
    --color-misplaced-letter: #e6bf00;
    --color-green-dark: #004d00;
    --color-red-dark: #990000;
}

body {
    font-family: sans-serif;
    --game-spacing: 8px;
}

@media (max-height: 600px) {
    body {
        --game-spacing: 8px;
    }
}
